import React, { useEffect, useLayoutEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { GatsbyImage } from "gatsby-plugin-image";
import Document from "../../components/svgs/Document/Document";
import {
  wrap,
  link,
  svg,
  text,
  linkreg,
  wrapAnchor,
  commentAdd,
  commentBtn,
  backContainer,
  iconWrap,
} from "./Project.module.scss";
// import AnchorSvg from "../../components/svgs/Anchor/Anchor";
import Card from "@material-ui/core/Card";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import useLang from "../../contexts/Language/LanguageContext";
import useWrapRef from "../../contexts/WrapRef/WrapRefContext";
import { TextField, IconButton, Button } from "@material-ui/core";
// import CommentIcon from "@mui/icons-material/Comment";
// import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { getCommentsBySlug, postComment } from "../../utils/manageComments";
import Comment from "../../components/comment/Comment";
import { format } from "date-fns";
import { usePageCounter } from "../../utils/hooks/usePageCounter";
import SocialShareButtons from "../../components/social-share/SocialShareButtons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export const query = graphql`
  query ($id: String!) {
    allContentfulProject(filter: { reference: { id: { eq: $id } } }) {
      edges {
        node {
          title
          titleen
          publishedDate
          slug
          body {
            raw
            references {
              __typename
              ... on ContentfulAsset {
                contentful_id
                title
                gatsbyImageData
                file {
                  url
                  contentType
                }
              }
            }
          }
          description {
            raw
            references {
              __typename
              ... on ContentfulAsset {
                contentful_id
                title
                gatsbyImageData
                file {
                  url
                  contentType
                }
              }
            }
          }
          bodyen {
            raw
            references {
              __typename
              ... on ContentfulAsset {
                contentful_id
                title
                gatsbyImageData
                file {
                  url
                  contentType
                }
              }
            }
          }
          descriptionen {
            raw
            references {
              __typename
              ... on ContentfulAsset {
                contentful_id
                title
                gatsbyImageData
                file {
                  url
                  contentType
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function Project(props) {
  const lang = useLang();
  const wrapCtx = useWrapRef();
  const [addCommentVisible, setAddCommentVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const url = props.location.href;
  const title = props.data.allContentfulProject.edges[0]?.node.title;
  const isValid = Boolean(
    comment && comment.length > 3 && username && username.length > 3
  );

  const isMultiNewsProject = Boolean(
    props.data.allContentfulProject?.edges?.length > 1
  );
  useEffect(() => {
    fetchComments();
  }, []);
  useLayoutEffect(() => {
    if (wrapCtx && wrapCtx.wrapRef && wrapCtx.wrapRef.current) {
      wrapCtx.wrapRef.current.scrollTo(0, 0);
    }
  }, []);
  const views = usePageCounter(props.pageContext.slug);
  const fetchComments = async () => {
    try {
      const data = await getCommentsBySlug(props.pageContext.slug);
      setComments(data.sort((a, b) => b.time.seconds - a.time.seconds));
      // setComments(data);
    } catch (err) {
      console.warn("error fetching firestore data ", err);
    }
  };
  const onSendComment = async () => {
    try {
      await postComment({
        comment: comment,
        slug: props.pageContext.slug,
        username: username,
      });
      setComment("");
      setUsername("");
      setAddCommentVisible(false);
      await fetchComments();
    } catch (err) {
      console.warn("firestore err", err);
    }
  };
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <>
            {node.data.target.gatsbyImageData && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <GatsbyImage
                  style={{ maxWidth: "100%", maxHeight: "400px" }}
                  image={node.data.target.gatsbyImageData}
                  alt={node.data.target.title}
                />
              </div>
            )}
            {node.data.target.file.contentType === "application/pdf" && (
              <div className={wrap}>
                <div className={svg}>
                  <Document />
                </div>
                <a
                  className={link}
                  href={node.data.target.file.url}
                  download
                  target="_blank"
                >
                  {node.data.target.title}
                </a>
              </div>
            )}
            {node.data.target.file.contentType === "application/docx" && (
              <div className={wrap}>
                <div className={svg}>
                  <Document />
                </div>
                <a
                  className={link}
                  href={node.data.target.file.url}
                  download
                  target="_blank"
                >
                  {node.data.target.title}
                </a>
              </div>
            )}
            {node.data.target.file.contentType === "application/doc" && (
              <div className={wrap}>
                <div className={svg}>
                  <Document />
                </div>
                <a
                  className={link}
                  href={node.data.target.file.url}
                  download
                  target="_blank"
                >
                  {node.data.target.title}
                </a>
              </div>
            )}
            {node.data.target.file.contentType === "video/mp4" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <video
                  src={node.data.target.file.url}
                  style={{ maxWidth: "100%" }}
                  title={node.data.target.title}
                  allowFullScreen={true}
                  controls
                />
              </div>
            )}
          </>
        );
      },
      [INLINES.HYPERLINK]: (node) => {
        return (
          <div className={wrapAnchor}>
            <a
              className={linkreg}
              target="_blank"
              rel="noreferrer"
              href={node.data.uri}
            >
              {node.content[0].value}
            </a>
          </div>
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return node.content.length > 1 ? (
          <div>{children}</div>
        ) : (
          <p className={text}>{children}</p>
        );
      },
    },
  };
  return (
    <div role="list">
      {props.data.allContentfulProject.edges.map((e) => {
        return (
          <div role="listitem">
            <Card style={{ padding: "18px", marginBottom: "20px" }}>
              <h1 style={{ color: "black", marginBottom: "2px" }}>
                {lang === "mne"
                  ? e.node.title
                  : e.node.titleen
                  ? e.node.titleen
                  : e.node.title}
              </h1>
              <p style={{ fontSize: "10px", color: "gray", marginTop: "0px" }}>
                {format(new Date(e.node.publishedDate), "dd.MM.yyyy")}
              </p>
              {isMultiNewsProject
                ? renderRichText(
                    lang === "mne"
                      ? e.node.description
                      : e.node.descriptionen
                      ? e.node.descriptionen
                      : e.node.description,
                    options
                  )
                : renderRichText(
                    lang === "mne"
                      ? e.node.body
                      : e.node.bodyen
                      ? e.node.bodyen
                      : e.node.body,
                    options
                  )}
              {!isMultiNewsProject ? (
                <>
                  <div className={commentAdd}>
                    <div className={commentBtn}>
                      <Button
                        color="primary"
                        component="span"
                        onClick={() => setAddCommentVisible((v) => !v)}
                      >
                        {lang == "mne" ? "Komentariši" : "Comment"}
                      </Button>
                    </div>
                    {addCommentVisible ? (
                      <div className={commentAdd}>
                        <TextField
                          required
                          id="username"
                          label={lang == "mne" ? "Ime" : "Name"}
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                        <TextField
                          id="comment"
                          label={lang == "mne" ? "Komentar" : "Comment"}
                          required
                          multiline
                          rows={4}
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />
                        <Button
                          disabled={!isValid}
                          style={{ maxWidth: "100px" }}
                          color="primary"
                          onClick={onSendComment}
                        >
                          {lang == "mne" ? "Pošalji" : "Send"}
                        </Button>
                      </div>
                    ) : null}
                  </div>
                  <div role="list">
                    {comments.map((c) => (
                      <Comment
                        key={c.value}
                        comment={c.value}
                        username={c.username}
                        published={c.time.seconds}
                      />
                    ))}
                  </div>
                  <div className={backContainer}>
                    <Link
                      aria-label={lang === "en" ? "Back" : "Nazad"}
                      title={lang === "en" ? "Back" : "Nazad"}
                      to="/projects"
                    >
                      <ArrowBackIcon
                        style={{
                          color: "black",
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </Link>
                    <div aria-label="broj pregleda" className={iconWrap}>
                      <VisibilityOutlinedIcon style={{ marginRight: 5 }} />
                      <span>{views ? views : 0}</span>
                    </div>
                  </div>
                  <div>
                    <SocialShareButtons title={title} url={url} />
                  </div>
                </>
              ) : (
                <>
                  <div className={backContainer}>
                    <Link
                      aria-label={lang === "en" ? "Back" : "Nazad"}
                      title={lang === "en" ? "Back" : "Nazad"}
                      to="/projects"
                    >
                      <ArrowBackIcon
                        style={{
                          color: "black",
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </Link>
                    <Link
                      aria-label={lang === "en" ? "More" : "Više"}
                      title={lang === "en" ? "More" : "Više"}
                      to={`/single-project/${e.node.slug}`}
                    >
                      <ExitToAppIcon
                        style={{
                          color: "black",
                          width: "40px",
                          height: "40px",
                          display: "block",
                          marginLeft: "auto",
                        }}
                      />
                    </Link>
                  </div>
                </>
              )}
            </Card>
          </div>
        );
      })}
    </div>
  );
}
