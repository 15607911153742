// extracted by mini-css-extract-plugin
export var wrap = "Project-module--wrap--cii6V";
export var link = "Project-module--link--9lt3z";
export var linkreg = "Project-module--linkreg--2XlGV";
export var svg = "Project-module--svg--1Sbmd";
export var text = "Project-module--text--2LFX3";
export var wrapAnchor = "Project-module--wrapAnchor--10VXf";
export var commentAdd = "Project-module--commentAdd--U7KK9";
export var commentBtn = "Project-module--commentBtn--3XLdV";
export var backContainer = "Project-module--backContainer--2zKHl";
export var iconWrap = "Project-module--iconWrap--1iQbo";